<template>
<div class='termsPrivacy'>
    <v-container>
        <v-row>
            <v-col style="text-align: left;">
                <v-btn @click="$router.go(-1)" text><v-icon>mdi-chevron-left</v-icon></v-btn>
                <v-tabs v-model="activeTab">
                    <v-tab href="#Terms" value="Terms">Terms & Conditions</v-tab>
                    <v-tab href="#Privacy" value="Privacy">Privacy Policy</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
    </v-container>
    <v-container fluid style="background-color: whitesmoke;">
        <v-row>
            <v-col style="text-align: left;">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-tabs-items v-model="activeTab" style="background-color: transparent;">
                                <v-tab-item value="Terms">
                                    <str index="footer.terms_of_use.content"/>
                                </v-tab-item>
                                <v-tab-item value="Privacy">
                                    <str index="footer.privacy_policy.content"/>
                                    <!-- <span v-html="$store.getters.labels.footer.privacy_policy.content._text[language].replaceAll('\n','<br>')"/> -->
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
export default {
    created: function(){
        this.activeTab = this.$route.name
    },
    data: function(){
        return {
            activeTab: null
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        route: function(){
            return this.$route.name
        }
    }
}
</script>

<style lang='scss'>

    .termsPrivacy {

        .v-tabs {
            @media(max-width: 575px){ // xs < 576
                margin-bottom: 16px;
            }
        }

        .v-slide-group__wrapper {
            @media(max-width: 575px){ // xs < 576
                height: fit-content;
            }

            .v-slide-group__content {
                @media(max-width: 575px){ // xs < 576
                    display: block;
                }

                .v-tabs-slider-wrapper {
                    color: $red_light;
                }

                .v-tab--active {
                    color: $red_light;
                    background-color: whitesmoke;
                    background-color: rgb(190, 30, 46, 0.1);

                }

                .v-tab {
                    @media(max-width: 575px){ // xs < 576
                        height: 32px;
                    }

                    &:before {
                        background-color: $red_light !important;
                    }
                }
            }
        }
    }
</style>
